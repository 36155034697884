import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

import LanguageSwitch from 'elements/LanguageSwitch';
import MultiLevelMenu from 'elements/MultiLevelMenu.js';

import { getLanguage, getTranslation } from 'utils/DictionaryUtils.js';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function MenuSlider(props) {
    let location = useLocation();

    useEffect(() => {
        if (props.onSliderClose())
            props.onSliderClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <motion.div
            className={'fixed w-full h-full z-50 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0.9999, transitionEnd: { display: 'none' } } }}>

            <motion.div
                className='absolute w-full h-full bg-white bg-opacity-70'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
                onClick={() => props.onSliderClose()} />

            <div className='absolute right-0 h-full w-3/4 max-w-sm filter'>

                <motion.div
                    className='bg-sznkki-gray-lightest shadow-xl h-full flex flex-col gap-6 pt-5 pb-6'
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    style={{ originX: 1, originY: 0.5, transformPerspective: 1250 }}
                    transition={{ duration: 0.35 }}
                    variants={{ visible: { rotateY: 0, display: 'flex' }, hidden: { rotateY: -90, transitionEnd: { display: 'none' } } }}>

                    <div className='relative w-full flex px-6 h-10' >

                        <Link to='/' className='flex-1'>
                            <img className='w-44 pr-2 mb-2' alt='logo' src={'/assets/images/logo-' + getLanguage() + '.svg'} />
                            {/* <div className='text-sznkki-gray font-alternative font-bold text-xs flex flex-col items-centerr justify-centerr'>
                                <div>Törvényesség.</div>
                                <div>Pontosság.</div>
                                <div>Innováció.</div>
                            </div> */}
                        </Link>

                        {/* <OrbButton
                            className='w-9 h-9'
                            data={{ iconName: 'FaTimes', iconClassName: 'text-lg', backgroundClassName: 'bg-white bg-opacity-30' }}
                            onClick={() => props.onSliderClose()} /> */}
                    </div>

                    <MultiLevelMenu
                        className='w-full flex-1 overflow-y-auto overflow-x-hidden px-6'
                        data={getValueFromParameterMap(getWebsite().globalContent, 'menu')}
                        baseLevel={0}
                        firstLevelKey='itemList'
                        activeClassName='font-semibold'
                        baseClassName='font-thin tracking-wider'
                        mainClassName='text-sm uppercase'
                        subClassName='text-xs'
                        orbClassName='bg-sznkki-yellow'
                        iconClassName='text-white' />

                    {/* <img className='w-48' alt='logo' src='/assets/images/logo.png' /> */}

                    <div className='flex flex-col gap-2 items-center justify-center'>
                        <div className='text-xs text-sznkki-gray-dark '>Nyelv - Language</div>
                        <LanguageSwitch />
                    </div>

                    <div className='w-full flex-col items-center justify-center text-center text-xxs font-thin leading-5 px-6'>
                        {'2022 © ' + getTranslation('copyright')}<br /> {getTranslation('title')}
                    </div>

                </motion.div>

            </div>

        </motion.div >
    );
}