
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'

import MenuBlock from 'components/MenuBlock.js';
import MenuSlider from 'components/MenuSlider.js';

import { getTranslation } from 'utils/DictionaryUtils.js';
import { getLanguage } from 'utils/DictionaryUtils.js';

export default function Header(props) {
    const [sliderOpen, setSliderOpen] = useState(false);

    return (
        <header className={'fixed sm:relative bg-white bg-opacity-90 w-full flex flex-col items-center justify-center z-50 shadow-3xl sm:shadow-none ' + (props.className || '')}>

            <div className='w-full block sm:hidden'>
                <MenuSlider className='flex sm:hidden' open={sliderOpen} onSliderClose={() => setSliderOpen(false)} />
            </div>

            <div className='w-full max-w-sznkki-boxed py-3 sm:py-10 flex flex-row items-end justify-between site-px'>

                <Link to='/'><img className='h-[50px] lg:h-[80px]' alt='logo' src={'/assets/images/logo-'+ getLanguage() +'.svg'} /></Link>

                <div className='hidden sm:block font-alternative text-sznkki-yellow font-bold text-lg lg:text-2xl' >{getTranslation('motto')}</div>

                <div className={'block sm:hidden z-50 ' + (sliderOpen ? 'fixed right-4' : 'block')}> <Hamburger size={25} color="#595959" direction="left" toggled={sliderOpen} toggle={setSliderOpen} /></div>

            </div>

            <MenuBlock className='hidden sm:flex' />

        </header>
    )
}