
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';

import DynamicIcon from 'elements/DynamicIcon.js';

export default function MenuLister(props) {
    var { className, data, firstLevelKey, ...other } = props;

    return (
        <div className={className || ''}>
            {(data && data[firstLevelKey]) && data[firstLevelKey].map((item) => <MenuListerItem key={item.id} data={item} {...other} />)}
        </div>
    );
}

function MenuListerItem(props) {
    const baseClassName = props.baseClassName || '';
    const mainClassName = props.mainClassName || '';
    const subClassName = props.subClassName || '';

    const activeClassName = props.activeClassName || '';

    var { className, data, ...other } = props;

    let isSub = (props.data.level - props.baseLevel) > props.baseLevel;

    return (
        <div className={(props.className || '')}>

            <div className={'w-full flex flex-row items-center justify-center ' + (isSub ? 'py-1.5' : 'py-2')}>

                <NavLink
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    style={{ paddingLeft: props.data.level > 3 ? (props.data.level * 2 + 'px') : '0px' }}
                    to={props.data.fullPath}
                    activeClassName={activeClassName}
                    className={'flex-1 transition opacity-75 hover:opacity-100 ' + baseClassName + ' ' + (isSub ? subClassName : mainClassName)}>
                    {(isSub ? '- ' : '') + props.data.title}
                </NavLink>

            </div>

            {props.data.childList ? <div className='w-full flex-col'>
                {props.data.childList.map((item, index) => (<MenuListerItem key={index} data={item} {...other} />))}
            </div> : null}

        </div>
    );
}