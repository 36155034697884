import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';
// import DynamicIcon from 'elements/DynamicIcon.js';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function MenuBlock(props) {
    return (
        <div className={'h-full flex flex-row px-3 items-stretch justify-center border-b border-sznkki-gray-lightest ' + (props.className || '')}>
            {getValueFromParameterMap(getWebsite().globalContent, 'menu') ? getValueFromParameterMap(getWebsite().globalContent, 'menu').itemList.map((item) => <MenuBlockMainItem key={item.id} data={item} />) : null}
        </div>
    );
}

function MenuBlockItemDropdown(props) {
    const animationOrigin = props.animationOrigin || 'y';
    return (
        <motion.div
            className={'flex flex-col ' + (props.level % 2 !== 0 ? 'bg-sznkki-yellow ' : 'bg-sznkki-yellow-dark ') + (props.className || '')}
            initial='hidden'
            animate={props.open ? 'visible' : (props.collapse && props.enableCollapse ? 'collapse' : 'hidden')}
            variants={{
                visible: { opacity: 1, x: 0, y: 0, display: 'flex' },
                hidden: { opacity: 0, x: animationOrigin === 'y' ? 0 : 20, y: animationOrigin === 'y' ? 20 : 0, transitionEnd: { display: 'none' } },
                collapse: { opacity: 0, x: 0, y: 0, transitionEnd: { display: 'none' } }
            }}>
            {props.children}
        </motion.div>
    );
}

function MenuBlockMainItem(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className={'relative flex group ' + (props.className || '')} onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>

            <NavLink className='px-3 py-4 flex items-center text-sm uppercase whitespace-nowrap transition opacity-75 group-hover:opacity-100 tracking-wide' to={props.data.fullPath}>
                {props.data.title}
            </NavLink>

            {props.data.childList ? <div className='absolute w-full bottom-0 flex justify-center'>
                <MenuBlockItemDropdown className='absolute min-w-80 max-w-96 top-0' open={dropdownOpen} level={props.data.level}>
                    {props.data.childList.map((item, index) => (<MenuBlockSubItem key={index} data={item} collapse={!dropdownOpen} enableCollapse />))}
                </MenuBlockItemDropdown>
            </div> : null}

        </div>
    );
}

function MenuBlockSubItem(props) {
    const getMainTitle = (label) => {
        if (props.data.level > 2)
            return (props.data.level > 2 ? '- ' : '') + props.data.title;
        else if (props.data.title.indexOf('(') > -1)
            return props.data.title.slice(0, props.data.title.indexOf('('));
        else
            return props.data.title;
    };

    const getSubTitle = () => {
        if (props.data.title.indexOf('(') > -1)
            return props.data.title.slice(props.data.title.indexOf('('), props.data.title.length).replace('(', '').replace(')', '');
        else
            return null
    };

    return (
        <div className={'flex flex-col ' + (props.data.level < 3 ? 'first:pt-2 last:pb-2 ' : '') + (props.className || '')} style={{ paddingLeft: props.data.level > 3 ? (props.data.level * 2 + 'px') : '0px' }}>

            <Link className={'flex flex-col gap-0.5 px-4 py-1 text-xxs tracking-wide transition opacity-75 hover:opacity-100 ' + (props.data.level < 3 ? 'uppercase font-semibold' : '')} to={props.data.fullPath}>
                {/* {getMainTitle()} */}

                {getSubTitle() ? <>
                    <div className='font-light normal-case'>{getMainTitle()}</div>
                    {getSubTitle()}
                </> : getMainTitle()}

            </Link>

            {props.data.childList ? <div className='flex flex-col'>
                {props.data.childList.map((item, index) => (<MenuBlockSubItem key={index} data={item} collapse={props.collapse} enableCollapse={props.enableCollapse} />))}
            </div> : null}

        </div>
    );
}