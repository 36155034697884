import React from 'react';
import { Link } from 'react-router-dom';

import { NBossCMSPage } from 'cms/NBossCMS.js';

export default function Error404Page(props) {
    return (
        <NBossCMSPage pageprops={props} className={'w-full flex-1 flex flex-col justify-center items-center'}>

            <div className='max-w-tv-boxed mx-4 h-full text-center pt-8 pb-16 flex flex-col gap-2'>
                <h1 className='text-8xl sm:text-9xl text-sznkki-yellow font-bold font-alternative'>404</h1>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-lg sm:text-2xl font-alternative'>A keresett oldal nem található.</h2>
                    <div className='text-xs sm:text-sm text-sznkki-gray-light'>
                        <div className='inline sm:block'>Elnézést kérünk, ez az oldal jelenleg nem elérhető vagy nem létezik. </div>
                        <div className='inline sm:block'>Kérjük, ellenőrizze a címet vagy látogasson el <Link to='/' className='text-sznkki-yellow font-semibold'>főoldalunkra</Link>.</div>
                    </div>
                </div>
            </div>

        </NBossCMSPage>
    );
}