const globalConfig = {
    'domainName': 'sznkki.hu',
    'etc': {
        'cookiePerfix': '_sznkki',
        'officeEmail': 'iroda@sznkki.hu',
        'privacyPolicyPath': '/adatkezeles',
        'cookiePolicyPath': {
            'hu-HU': '/cookie-tajekoztato',
            'en-GB': '/cookie-information'
        }
    },
    'linkListMap': {
        "hu-HU": [
            {
                'title': 'Közjegyzői elektronikus rendszerek',
                'list': [
                    { 'title': 'Fizetési meghagyásos eljárás', 'url': 'https://fmh.mokk.hu/' },
                    { 'title': 'Hitelbiztosítéki Nyilvántartás', 'url': 'https://hbny.mokk.hu/' },
                    { 'title': 'Közjegyzői hirdetmények', 'url': 'https://hirdetmenyek.mokk.hu/' },
                ]
            },
            {
                'title': 'Elektronikus ügyintézés',
                'list': [
                    { 'title': 'Tájékoztató az elektronikus ügyintézésről', 'url': 'https://mokk.hu/hasznos-informaciok/e-ugyintezes/' },
                ]
            },
            {
                'title': 'Űrlapok',
                'list': [
                    { 'title': 'Papíralapú űrlapok', 'url': 'https://mokk.hu/ugyfeleknek/kerelmek.php' },
                    { 'title': 'Elektronikus űrlapok', 'url': 'https://mokk.hu/kerelmek-urlapok/elektronikus-urlapok-anyk/' },
                ]
            },
            {
                'title': 'Adatvédelem',
                'list': [
                    { 'title': 'Tájékoztató a közjegyzői hatáskörbe tartozó eljárásokkal összefüggésben megvalósuló adatkezelésekről', 'url': ' https://mokk.hu/wp-content/uploads/2023/06/Tajekoztato-a-kozjegyzoi-hataskorbe-tartozo-eljarasokkal-osszefuggesben-....pdf' },
                ]
            }
        ],
        "en-GB": [
            {
                'title': 'Notarial electronic systems',
                'list': [
                    { 'title': 'Order for payment', 'url': 'https://fmh.mokk.hu/' },
                    { 'title': 'Security Interest Register', 'url': 'https://hbny.mokk.hu/' },
                    { 'title': 'Notarial notices', 'url': 'https://hirdetmenyek.mokk.hu/' },
                ]
            },
            {
                'title': 'Electronic administration',
                'list': [
                    { 'title': 'Information on electronic administration', 'url': 'https://mokk.hu/ugyfeleknek/tajekoztato-az-elektronikus-ugyintezesrol.php' },
                ]
            },
            {
                'title': 'Forms',
                'list': [
                    { 'title': 'Paper forms', 'url': 'https://mokk.hu/ugyfeleknek/kerelmek.php' },
                    { 'title': 'Electronic forms', 'url': 'https://mokk.hu/ugyfeleknek/elektronikus-urlapok.php' },
                ]
            },
            {
                'title': 'Data protection',
                'list': [
                    { 'title': 'Information on data processing in the context of procedures falling within the competence of notaries', 'url': 'https://mokk.hu/regioldal/pdf/linkgyujto/kozadat/tajekoztato_kjoi_eljarasok.pdf' },
                ]
            }
        ]
    },
    'mapStlyes': [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffda23"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "hue": "#ff0000"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#ffda23"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ]
}

export default globalConfig;