import globalConfig from 'config/globalConfig.js';
import { getLanguage } from 'utils/DictionaryUtils.js';

export default function LinkBar(props) {
    return (
        <div className={'flex flex-col gap-4 text-sm text-center lg:text-left lg:pr-8 tracking-wide ' + (props.className || '')}>
            {/* {console.log(globalConfig.linkListMap[getLanguage()])} */}
            {globalConfig.linkListMap[getLanguage()].map((item, index) => (
                <div key={index} className='flex flex-col gap-4 sm:gap-6 lg:gap-4'>
                    <div className='font-semibold text-sznkki-gray'>{item.title + ':'}</div>
                    <div className='flex flex-col gap-4'>
                        {item.list.map((subItem, subIndex) => (
                            <div key={subIndex} className='flex flex-col gap-2 sm:gap-4 lg:gap-2'>
                                <div className='text-sznkki-gray-light'>{subItem.title}</div>
                                <div className='content'><a className='' href={subItem.url}>Link ››</a></div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div >
    )
}