import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import DynamicIcon from 'elements/DynamicIcon.js';

export default function Modal(props) {
    return (
        <ModalBackground onBackgroundClick={() => props.onClose()}>

            <motion.div
                className='w-full max-w-lg p-4'
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}>

                <div className='bg-white shadow-xl overflow-hidden'>
                    {props.children}
                </div>

            </motion.div>

        </ModalBackground>
    );
}

export function ModalBackground(props) {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='fixed top-0 left-0 z-50 w-full h-full'>
            <div className='relative w-full h-full flex items-center justify-center'>
                <div className='absolute top-0 left-0 w-full h-full bg-opacity-30 bg-black backdrop-filter backdrop-grayscale-50 z-0' onClick={() => props.onBackgroundClick()} />
                <div className='z-10'>
                    {props.children}
                </div>
            </div>
        </motion.div>
    );
}

export function ModalHeader(props) {
    return (
        <div className='w-full px-4 sm:px-6 py-6 bg-sznkki-yellow font-alternative text-white text-lg sm:text-xl font-bold'>
            {props.children}
        </div>
    );
}

export function ModalContent(props) {
    return (
        <div className='w-full flex flex-col gap-4 p-4 sm:p-6'>
            {props.children}
        </div>
    );
}

export function ModalButton(props) {
    const className = 'flex flex-row gap-2 items-center justify-center bg-sznkki-gray-light disabled:bg-sznkki-gray-lightest transition hover:bg-sznkki-gray p-4 text-white text-sm font-semibold disabled:cursor-not-allowed ' + (props.className || '')

    if (props.to)
        return (<Link className={className} to={props.to} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></Link>)
    else
        return (<button className={className} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></button>);
}

function ModalButtonContent(props) {
    return (
        <>
            {props.icon && <DynamicIcon className={'text-sm'} iconName={props.icon} />}
            {props.label}
        </>
    );
};
