import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import globalConfig from 'config/globalConfig.js';
import { getTranslation, getLanguage } from 'utils/DictionaryUtils.js';

export default function CookieConsent(props) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem(globalConfig.etc.cookiePerfix + 'CookieConsent')
        let showPopup = cookieConsent ? false : true;
        if (showPopup)
            setTimeout(() => setVisible(true), 3000);
    }, [])

    return (
        <motion.div
            className={'fixed bottom-0 left-0 w-full md:w-96 bg-sznkki-gray-lightest shadow-2xl md:m-6 p-4 sm:p-6 ' + (props.className || '')}
            initial='hidden'
            animate={visible ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, y: 0, display: 'flex' }, 'hidden': { opacity: 0, y: 300, transitionEnd: { display: 'none' } } }} >

            <div className='flex flex-col gap-2 text-whitee'>

                <div className='font-bold text-lg'>{getTranslation('cookie-settings')}</div>
                <div className='text-xs leading-5'>{getTranslation('cookie-description')}</div>

                <Link to={globalConfig.etc.cookiePolicyPath[getLanguage()]} className='font-bold text-xs transition hover:text-sznkki-yellow'>{getTranslation('cookie-information')}</Link>

                <div className='mt-1 w-full'>
                    <button
                        className='transition w-full p-3 bg-sznkki-gray-light hover:bg-sznkki-yellow text-white flex flex-row gap-4 items-center justify-center uppercase font-bold text-sm'
                        onClick={() => { setVisible(false); localStorage.setItem((globalConfig.etc.cookiePerfix + 'CookieConsent'), Date.now()); }}>
                        {getTranslation('cookie-accept')}
                    </button>
                </div>

            </div>

        </motion.div>
    );
}