import DynamicIcon from 'elements/DynamicIcon.js';
import { Link } from 'react-router-dom';

import { getTranslation } from 'utils/DictionaryUtils.js';
import globalConfig from 'config/globalConfig.js'
import LanguageSwitch from 'elements/LanguageSwitch';

export default function ContactBar(props) {
    return (
        <div className={'flex flex-col gap-6 items-center text-sm sm:text-xs text-center text-sznkki-gray-light tracking-wide leading-5 ' + (props.className || '')}>

            <div className='flex flex-col gap-3 items-center'>
                <div className='text-sznkki-gray-dark '>Nyelv - Language</div>
                <LanguageSwitch />
            </div>

            <div className='flex flex-col gap-3 items-center'>
                <div className='text-sznkki-gray-dark '>{getTranslation('contact')}</div>
                <div className='flex flex-col gap-1 items-center '>
                    <div>2040 Budaörs, Építők útja. 2-4.</div>
                    <div>tel.: <a href='tel:+3623799140'>+ 36 (23) 799 - 140</a></div>
                    {/* <div>fax.: <a href='tel:+3623799149'>+ 36 (23) 799 - 149</a></div> */}
                </div>
                <div className='flex flex-row gap-2 items-center justify-center text-lg'>
                    <a className='cursor-pointer transition opacity-50 hover:opacity-100' href={'mailto:' + globalConfig.etc.officeEmail}><DynamicIcon className='cursor-pointer transition opacity-50 hover:opacity-100' iconName='FaAt' /></a>
                    <Link className='cursor-pointer transition opacity-50 hover:opacity-100' to='/kapcsolat'><DynamicIcon iconName='FaMapMarkerAlt' /></Link>
                </div>
            </div>

            <div className='flex flex-col gap-0 items-center mb-1'>
                <img className='mb-2' alt='címer' src='/assets/images/cimer-2.png' />
                <div className='font-semibold'>BUDAÖRS</div>
                <div>{'— ' + getTranslation('seat-number') + ' —'}</div>
                <div>{getTranslation('seat-title')}</div>
            </div>

            <div className='flex flex-col gap-1 items-center'>
                <div className='font-semibold uppercase'>{getTranslation('jurisdiction')}</div>
                <div>Biatorbágy, Budaörs, Herceghalom, Törökbálint</div>
            </div>

            <div className='flex flex-col gap-1 items-center'>
                <div className='font-semibold uppercase'>{getTranslation('seat-code')}</div>
                <div>14042</div>
            </div>

        </div>
    )
}