import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from 'components/Header.js';
import Footer from 'components/Footer.js';
import SimplePage from 'pages/SimplePage.js';
import Error404Page from 'pages/Error404Page.js';
import LinkBar from 'components/LinkBar.js';
import ContactBar from 'components/ContactBar.js';
import Breadcrumb from 'components/Breadcrumb.js';
import LeftMenu from 'components/LeftMenu.js';
import CookieConsent from 'elements/CookieConsent';
import ModalImportantMessage from 'elements/modal/ModalImportantMessage';

import CmsFrontendControl from 'services/CmsFrontendControl.js';
import { getWebsite, setWebsite } from 'utils/WebsiteUtils.js';

export default function NBossCMSClient(props) {
    const [website, _setWebsite] = useState(null);
    const [routes, setRoutes] = useState(null);

    const pageDictionary = {
        'main-layout': { page: SimplePage },
        'content-page': { page: SimplePage, templateProps: { menu: true } },
        '404': { page: Error404Page },
    };

    const createRoutes = (website) => {
        let _routes = [];

        if (website.mainPage)
            _routes.push({ path: '/', component: pageDictionary[website.mainPage.pageTemplate].page, pageKey: website.mainPage.pageKey });

        for (let key in website.sitemap)
            if (website.sitemap[key].pageTemplate && pageDictionary[website.sitemap[key].pageTemplate])
                _routes.push({ path: key, component: pageDictionary[website.sitemap[key].pageTemplate].page, pageKey: website.sitemap[key].pageKey, templateProps: pageDictionary[website.sitemap[key].pageTemplate].templateProps });
            else
                console.log('[Missing template] ' + (website.sitemap[key].pageTemplate ? website.sitemap[key].pageTemplate : 'No template defined.') + ' [for] ' + key);

        _routes.push({ path: '*', component: pageDictionary['404'].page, pageKey: 'egyszeru-oldal' });

        return _routes;
    }

    useEffect(() => {
        CmsFrontendControl.getWebsite(props.domainName, window.location.pathname).then(result => { _setWebsite(result) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (website !== null) {
            setWebsite(website);
            setRoutes(createRoutes(website))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website]);

    if (website && routes) {
        return (
            <Router>
                <div className='min-h-screen flex flex-col justify-between'>

                    <div className='flex-1 flex flex-col items-center'>

                        <Header className='z-20 mb-4 sm:mb-8' />

                        <div className={'w-full mt-20 sm:mt-0 flex-1 flex flex-col lg:flex-row gap-8 xl:gap-12 max-w-sznkki-boxed justify-stretch site-px pb-16'}>

                            <div className='sm:hidden lg:flex flex-col w-full sm:max-w-48 border-y sm:border-0 py-8 sm:py-0'>
                                <LeftMenu />
                                <LinkBar />
                            </div>

                            <div className='flex-1 flex flex-col gap-6 order-first lg:order-none' >
                                <Breadcrumb />
                                <Switch>{routes.map((route, index) => (<Route key={index} exact path={route.path} render={props => (<route.component {...props} pageKey={route.pageKey} templateProps={route.templateProps || {}} />)} />))}</Switch>
                            </div>

                            <ContactBar className='sm:hidden lg:flex w-full sm:max-w-48' />

                            <div className='hidden sm:flex lg:hidden flex-row border-t pt-8'>
                                <LinkBar className='flex-1 pr-8 border-r' />
                                <ContactBar className='flex-1 pl-8' />
                            </div>

                        </div>

                    </div>

                    <Footer className='z-10' />
                    <CookieConsent className='z-50' />
                    {getValueFromParameterMap(website.globalContent, 'popup') && <ModalImportantMessage className='z-50' data={getValueFromParameterMap(website.globalContent, 'popup')} />}

                </div>
            </Router >
        );
    } else {
        return (
            <div className='w-full min-h-screen flex flex-col justify-center items-center gap-3'>
                <img className='h-[50px] lg:h-[80px]' alt='logo' src='/assets/images/logo-hu-HU.svg' />
                <p className='text-sm font-bold text-sznkki-gray-light uppercase'>Betöltés...</p>
            </div>
        );
    }
}

export function NBossCMSPage(props) {
    useEffect(() => {
        if (checkPageLanguage(getWebsite().sitemap, window.location.pathname, getWebsite().currentLocale.key))
            CmsFrontendControl.getPage(props.pageprops.pageKey).then(result => { props.pageDataReady ? props.pageDataReady(result) : console.log('No pageDataReady callback defined.') });
        else
            // console.log('reload');
            window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkPageLanguage = (sitemap, path, localeKey) => {
        const hasSitemapItem = sitemap[path] ? true : false;
        // console.log('hasSitemapItem: ' + hasSitemapItem);
        const hasLocale = hasSitemapItem && (sitemap[path] && sitemap[path].localeList.indexOf(localeKey) > -1);
        // console.log('hasLocale: ' + hasLocale);
        const isMainPage = path === '/' || path === '';
        // console.log('isMainPage: ' + isMainPage);

        return (hasLocale || isMainPage || !hasSitemapItem) ? true : false;
    }

    return (<motion.div
        className={props.className}
        // style={{ originX: 0.5, originY: 0, transformPerspective: 2000 }}
        transition={{ duration: .3 }}
        initial={{ opacity: 0/*, rotateX: 0*/ }}
        animate={{ opacity: 1/*, rotateX: 0*/ }}
        exit={{ opacity: 1/*, rotateX: 0*/ }}>
        {props.children}
    </motion.div>);
}

export const getParameterEndMatch = (data, valueKey, end) => {
    if (data && valueKey && end)
        for (let key in data.parameterMap)
            if (key.endsWith(end))
                return data.parameterMap[key][valueKey];
}

export const getValueFromParameterMap = (data, key) => {
    if (data && key && data.parameterMap[key])
        return data.parameterMap[key].value;
    return null;
};

export const getNBossEndpoint = () => {
    return `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (typeof process.env.REACT_APP_API_PORT !== 'undefined' ? `:${process.env.REACT_APP_API_PORT}` : ``)}/${process.env.REACT_APP_API_NAME}/methods/`;
}