import { useEffect } from 'react';

import NBossCMSClient from 'cms/NBossCMS.js';

import globalConfig from 'config/globalConfig.js'
import nBossConnection from 'services/nBossConnection.js';

function App() {
  useEffect(() => {
    nBossConnection.setErrorHandler(error => {
      // if (typeof error.response.status !== 'undefined' && error.response.status === 500) alert('Internal Server Error!');  else 
      alert(error);
    });
  });

  return (<NBossCMSClient domainName={globalConfig.domainName} />);
}

export default App;
